import React from 'react'
import { Box } from 'rebass/styled-components'

const Columns = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                width: '100%' }}
            >
                <Box sx={{
                    bg: '#FCE38A',
                    display: 'flex',
                    flexGrow: '1',
                    height: '300px',
                    justifyContent: 'center',
                    margin: '2px',
                }}>
                    <h2>A little of column A...</h2>
                </Box>
                <Box sx={{
                    bg: '#FCE38A',
                    display: 'flex',
                    flexGrow: '1',
                    height: '300px',
                    justifyContent: 'center',
                    margin: '2px',
                }}>
                    <h2>A little of column B...</h2>
                </Box>
            </Box>
        </>
    )
}

export default Columns
