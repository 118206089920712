import React from 'react'
import { Box } from 'rebass/styled-components'

const Breakout = () => {
    const isSSR = typeof window === 'undefined'
    return (
        <>
            {!isSSR && (
                <Box sx={{
                    display: 'flex',
                    bg: '#95E1D3',
                    height: '400px',
                    justifyContent: 'center',
                    my: '2px',
                }}>
                    <h2>I am the Breakout module</h2>
                </Box>
            )}
        </>
    )
}

export default Breakout
