import React from 'react'
import Layout from '../../templates/Main'
import Columns from '../../modules/Columns'
import Hero from '../../modules/Hero'
import Breakout from '../../modules/Breakout'


const PostPage = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Hero />
            <Columns />
            <Breakout />
            <Columns />
        </Layout>
    )
}

export default PostPage
